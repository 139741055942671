import { getUser, saveForm } from "./db";

const userID = window.location.pathname;

const user = document.getElementById("user");
getUser(userID)
  .then((data) => {
    user.innerHTML = data.title;
    setAppeals(data?.appeal)
  })
  .catch(() => {
    user.innerHTML = "Дорогие друзья!";
    setAppeals()
  });

const setAppeals = (text = 'Вас') => {
  const appeals = document.getElementsByClassName("appeal");
  for (let item of appeals) {
    item.innerHTML = text;
  }
}

// const quizForm = document.getElementById("quiz-form");
// const quizResult = document.getElementById("quiz-result");
// const form = document.getElementById("form");

// form.addEventListener("submit", (e) => {
//   e.preventDefault();
//   const formData = new FormData(e.target);
//   const formProps = Object.fromEntries(formData);

//   quizForm.style.display = "none";
//   quizResult.style.opacity = 1;
//   saveForm({ userID, ...formProps });
// });

const joinBtn = document.getElementById("join");
joinBtn.addEventListener("click", () => {
  window.open("https://t.me/+k4Z5jgN6uRRiYjQy", "_blank");
});
