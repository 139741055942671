import { initializeApp } from "firebase/app";
import { getFirestore, collection, addDoc, getDoc, doc, deleteDoc } from "firebase/firestore/lite";

const firebaseConfig = {
  apiKey: "AIzaSyA3FqvCePw4qawqCwCzEh2GbHmh4-MIpb0",
  authDomain: "wedding-dc6cb.firebaseapp.com",
  projectId: "wedding-dc6cb",
  storageBucket: "wedding-dc6cb.appspot.com",
  messagingSenderId: "1092413667598",
  appId: "1:1092413667598:web:b541c8e85650602df5ff5f",
  measurementId: "G-ESDWTFWW5B",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export const saveForm = (...data) =>
  addDoc(collection(db, "quiz"), { ...data });

export const getUser = async (id) => {
    const docRef = doc(db, "users", id);
    const docSnap = await getDoc(docRef);
    return docSnap.data()
};